import React from 'react';
import { useAuth } from '../AuthContext.js';
import "../pages/private/private.css";

function PrivateRoute({ children }) {
    const { isLoading } = useAuth();
    if (isLoading) {
        return <div>Cargando ...</div>;
    }
    return children;// isAuthenticated ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
