import React, { useState } from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";

const CustomFields = () => {

    const [data, setData] = useState([]);

    const handleCreate = () => {};
    const handleEdit = () => {};
    const handleDelete = () => {};

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="p-4 content-container col-md-9 col-lg-10">
                    <h2 className="mb-4"><i class="bi bi-bar-chart-steps"></i>  Administración de Datos Personalizados
                    </h2>
                    <button className="btn btn-primary mb-3" onClick={handleCreate}>
                        Crear Nuevo Campo
                    </button>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Fecha de creación</th>
                            <th>Fecha de actualización</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((user) => (
                            <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.created_at}</td>
                                <td>{user.updated_at}</td>
                                <td>
                                    <button
                                        className="btn btn-warning btn-sm me-2"
                                        onClick={() => handleEdit(user)}
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDelete(user.id)}
                                    >
                                        Borrar
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CustomFields;