import React, { useState, useEffect } from "react";
import projectsService from "../../../services/projectsService.js";

const ModalProject = ({ isOpen, onClose, editingModal, loadContent, titleSingular }) => {

    const [formData, setFormData] = useState({
        project_name: "",
        description: "",
        started_at: "",
        deadline: "",
        active: true,
    });

    useEffect(() => {
        if (editingModal) {
            console.log(editingModal.started_at);
            setFormData({
                project_name: editingModal.project_name,
                description: editingModal.description,
                started_at: editingModal.started_at ? editingModal.started_at.split("T")[0] : "",
                deadline: editingModal.deadline ? editingModal.deadline.split("T")[0] : "",
                active: editingModal.active,
            });
        } else {
            setFormData({
                project_name: "",
                description: "",
                started_at: "",
                deadline: "",
                active: true,
            });
        }
    }, [editingModal]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const method = editingModal ? "PUT" : "POST";
        const payload = {
            ...formData,
            started_at: `${formData.started_at}`,
            deadline: `${formData.deadline}`,
        };

        if (method === "PUT") {
            projectsService.setProjects(editingModal.id, payload).then(r => {
                loadContent();
                onClose();
            });
        }

        if (method === "POST") {
            projectsService.createProject(payload).then(r => {
                loadContent();
                onClose();
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingModal ? `Editar ${titleSingular}` : `Crear ${titleSingular}`}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Nombre del {titleSingular}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="project_name"
                            value={formData.project_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Descripción</label>
                        <textarea
                            className="form-control"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows="3"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Fecha de Inicio</label>
                        <input
                            type="date"
                            className="form-control"
                            name="started_at"
                            value={formData.started_at}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Fecha de término</label>
                        <input
                            type="date"
                            className="form-control"
                            name="deadline"
                            value={formData.deadline}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <fieldset>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked={formData.active}
                                       onChange={handleChange}/>
                                <label className="form-check-label">Activado</label>
                            </div>
                        </fieldset>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        <i className="bi bi-save me-2"></i>
                        {editingModal ? "Guardar" : "Crear Proyecto"}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i>
                        Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalProject;
