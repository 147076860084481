import commonApiService from './commonApiService.js';

const createUser = async (userData) => {
    try {
        const response = await commonApiService.apiPrivate().post('/users', userData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const updateUser = async (userData) => {
    try {
        const response = await commonApiService.apiPrivate().put('/users', userData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const getUsers = async () => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get(`/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const getUser = async (userId) => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get(`/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const getUsersByClientId = async (clientId) => {
    try {
        const token = localStorage.getItem('access_token');
        const response = await commonApiService.apiPrivate().get(`/users?client_id=${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const deleteUser = async (clientId) => {
    try {
        const response = await commonApiService.apiPrivate().delete(`/users/${clientId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al eliminar cliente');
    }
};

const usersService = {
    createUser,
    updateUser,
    getUsers,
    getUsersByClientId,
    deleteUser,
    getUser
};

export default usersService;