import React, { createContext, useState, useContext, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import {decodeJWT, isTokenExpired} from './helpers/accessTokenHelper.js'

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigateTo = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        if (storedToken) {
            if (isTokenExpired(storedToken)) {
                localStorage.clear();
                navigateTo('/login?expired=1');
            } else {
                setIsAuthenticated(true);
                setIsLoading(false);
            }
        } else {
            navigateTo('/login');
        }
    }, [navigateTo]);

    const login = (accessToken) => {
        localStorage.setItem('access_token', accessToken);
        setIsAuthenticated(true);
        setIsLoading(false);

        const decodedToken = decodeJWT(accessToken);
        if(decodedToken.data.name === 'SystemDefaultSuperAdmin'){
            navigateTo('/municipios');
        } else {
            navigateTo('/proyectos');
        }
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        setIsAuthenticated(false);
        navigateTo('/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
