import React, { useState } from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";
import {Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";

const Activities = () => {

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="col-md-9 col-lg-10 p-4 content-container">
                    <h2 className="mb-4"><i className="bi bi-list-task"></i> Actividades</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-3 bg-light rounded shadow-sm">
                                <h5>Hoja de Ruta</h5>

                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Limpieza y Mantenimiento del Parque Central</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="p-3 bg-light rounded shadow-sm">
                                                        <h6>Limpieza y Mantenimiento del Parque Central</h6>
                                                        <textarea readOnly className="form-control form-control-lg"
                                                                  rows="5">
                                                            Realizar la limpieza general del Parque Central, incluyendo
                                                            recolección de residuos, poda de árboles, pintura de bancas
                                                            y restauración de áreas verdes. Esta tarea busca promover un
                                                            entorno limpio y seguro para los vecinos, fomentando el uso
                                                            responsable de los espacios públicos.
                                                        </textarea>
                                                        <Link to="/actividad" style={{marginTop:'20px'}} className="btn btn-primary mb-3">
                                                            Ir a la actividad
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Activities;