import React, { useState, useEffect } from "react";
import usersService from "../../../services/usersService.js";

const ModalUser = ({ isOpen, onClose, editingUser, clientId, getUsers, roles }) => {
    const [formData, setFormData] = useState({
        name: "",
        username: "",
        mail: "",
        uid: "",
        client_id: clientId,
        roles: [],
        current_role_id: "",
    });

    useEffect(() => {
        if (editingUser) {
            let current_role_id = ""
            editingUser.roles.forEach((role) => {
                current_role_id = role.id;
            });
            setFormData({
                name: editingUser.name || "",
                username: editingUser.username || "",
                mail: editingUser.mail || "",
                client_id: editingUser.client_id || "",
                uid: editingUser.uid || "",
                current_role_id: current_role_id
            });
        } else {
            setFormData({ name: "", username: "", mail: "", uid: "", roles: [], current_role_id: "" });
        }
    }, [editingUser]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        formData.client_id = clientId;
        if(editingUser) {
            try {
                formData.id = editingUser.id;
                await usersService.updateUser(JSON.stringify(formData))
                onClose();
                getUsers();
            } catch (error) {
                alert(error);
            }
        } else {
            try {
                await usersService.createUser(JSON.stringify(formData))
                onClose();
                getUsers();
            } catch (error) {
                alert(error);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingUser ? "Editar Usuario" : "Crear Usuario"}
                </h2>
                <form onSubmit={handleSubmit}>
                    {clientId && (
                        <input type="hidden" name="client_id" value={formData.client_id}/>
                    )}
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username">Usuario</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mail">Email</label>
                        <input
                            type="email"
                            id="mail"
                            name="mail"
                            value={formData.mail}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="uid">RUT</label>
                        <input
                            type="text"
                            id="uid"
                            name="uid"
                            value={formData.uid}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="roles">Roles</label>
                        <select
                            onChange={(e) => {
                                const roles = []
                                roles.push(JSON.parse(e.target.value));
                                formData.roles = roles;
                            }}
                            required
                            id="roles"
                            name="roles"
                            className="form-select">
                            <option value="">--- Seleccionar ---</option>
                            {roles.map((option, index) => (
                                <option
                                    key={index}
                                    value={JSON.stringify({"id":option.id, "name": option.name})}
                                    selected={formData.current_role_id === option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="btn btn-primary">
                            {editingUser ? "Actualizar" : "Crear"}
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={onClose}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalUser;
