import commonApiService from './commonApiService.js';

const getRoles = async () => {
    try {
        const response = await commonApiService.apiPrivate().get('/roles');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'Error al registrar');
    }
};

const rolesService = {
    getRoles
};

export default rolesService;