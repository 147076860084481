import React, { useState, useEffect } from "react";
import clientsService from "../../../services/clientsService.js";

const ModalClient = ({ loadContent, isOpen, onClose, editingModal }) => {
    const [formData, setFormData] = useState({
        uid: "",
        name: "",
        client_type: "public",
        address: "",
        city: "",
        state: "",
        province: "",
        country: "Chile",
        phone: "",
        email: "",
        website: "",
        contact_name: "",
        active: true
    });

    useEffect(() => {
        if (editingModal) {
            setFormData({
                uid: editingModal.uid || "",
                name: editingModal.name || "",
                address: editingModal.address || "",
                city: editingModal.city || "",
                state: editingModal.state || "",
                province: editingModal.province || "",
                phone: editingModal.phone || "",
                email: editingModal.email || "",
                website: editingModal.website || "",
                contact_name: editingModal.contact_name || "",
            });
        } else {
            setFormData({
                uid: "",
                name: "",
                client_type: "public",
                address: "",
                city: "",
                state: "",
                province: "",
                country: "Chile",
                phone: "",
                email: "",
                website: "",
                contact_name: "",
                active: true
            });
        }
    }, [editingModal]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = editingModal ? "PUT" : "POST";

        const payload = {
            ...formData
        };

        if (method === "PUT") {
            clientsService.updateClient(editingModal.id, payload).then(r => {
                loadContent();
                onClose();
            });
        }

        if (method === "POST") {
            clientsService.createClient(payload).then(r => {
                loadContent();
                onClose();
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content modal-content-lg">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2 className="modal-title">
                    {editingModal ? "Editar Municipio" : "Crear Municipio"}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>RUT</label>
                                <input
                                    type="text"
                                    name="uid"
                                    value={formData.uid}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Región</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Comuna</label>
                                <input
                                    type="text"
                                    name="province"
                                    value={formData.province}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Ciudad</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombre contacto</label>
                                <input
                                    type="text"
                                    name="contact_name"
                                    value={formData.contact_name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Dirección</label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Teléfono</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Sitio Web</label>
                                <input
                                    type="text"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        <i className="bi bi-save me-2"></i>
                        {editingModal ? "Actualizar" : "Crear"}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={onClose}>
                        <i className="bi bi-x-circle me-2"></i>
                        Cancelar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ModalClient;
