export function decodeJWT(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Error al decodificar el token:", error);
        return null;
    }
}

export function isTokenValid(token) {
    if (!token) {
        console.error("Token no proporcionado");
        return false;
    }

    const decoded = decodeJWT(token);

    if (!decoded || !decoded.exp) {
        console.error("El token no tiene un campo exp o es inválido");
        return false;
    }

    const currentTime = Math.floor(Date.now() / 1000); // Hora actual en segundos
    return decoded.exp > currentTime;
}

export function isTokenExpired(token) {
    if (!token) return true;

    try {
        const decoded = decodeJWT(token);
        const exp = decoded.exp;
        const currentTime = Date.now() / 1000;
        return exp < currentTime;
    } catch (error) {
        return true;
    }
}