import React, {useEffect} from 'react';
import {decodeJWT, isTokenExpired} from "../../helpers/accessTokenHelper.js";
import {useNavigate} from "react-router-dom";

function Loading() {

    const navigateTo = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        const decodedToken = decodeJWT(storedToken);
        if (storedToken) {
            if (isTokenExpired(storedToken)) {
                localStorage.clear();
                navigateTo('/login?expired=1');
            } else if(decodedToken.data.name === 'SystemDefaultSuperAdmin'){
                navigateTo('/municipios');
            } else {
                navigateTo('/proyectos');
            }
        } else {
            navigateTo('/login');
        }
    }, [navigateTo]);

    return (
        <div className="loading-container">
            <div className="spinner"></div>
            <p>Cargando ...</p>
        </div>
    );
}

export default Loading;