import React, { useState, useEffect } from "react";
import ModalClient from "./ModalClient.js";
import clientsService from "../../../services/clientsService.js";
import {useNavigate} from 'react-router-dom';

const Clients = () => {

    const [clients, setClients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingClient, setEditingClient] = useState(null);
    const navigateTo = useNavigate();

    const loadContent = () => {
        clientsService.getClients().then(data => {
            if(data.success){
                setClients(data.data);
            }
        });
    }

    useEffect(() => {
        loadContent();
    }, []);

    const handleOpenModalForCreate = () => {
        setEditingClient(null);
        setIsModalOpen(true);
    };

    const handleEditClient = (client) => {
        setEditingClient(client);
        setIsModalOpen(true);
    };

    const handleDeleteClient = async (clientId) => {
        if (window.confirm("¿Estás seguro que deseas borrar esta municipalidad?")) {
            try {
                await clientsService.deleteClient(clientId);
                setClients(clients.filter((client) => client.id !== clientId));
            } catch (error) {
                console.error("Error deleting client:", error);
            }
        }
    };

    const handleNavigateToUsers = (clientId) => {
        navigateTo("/usuarios/" + clientId);
    }

    const handleCloseSession = async () => {
        if(window.confirm("¿Seguro que quieres cerrar tu sesión?")){
            localStorage.removeItem('access_token');
            navigateTo('/login');
        }
    }

    return (
        <div className="container-fluid">
            <div className="row vh-100">
                <div className="col-md-12 col-lg-12 p-4 content-container">
                    <h2 className="mb-4">Administración de Municipios</h2>
                    <button className="btn btn-primary mb-3" onClick={handleOpenModalForCreate}>
                        Crear Nuevo Municipio
                    </button>
                    &nbsp;
                    <button className="btn btn-secondary mb-3" onClick={handleCloseSession}>
                        Cerrar Sesión
                    </button>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                            <th>Región</th>
                            <th>Comuna</th>
                            <th>Ciudad</th>
                            <th>Dirección</th>
                            <th>Contacto</th>
                            <th>Sitio web</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(clients) && clients.map((client) => (
                            <tr key={client.id}>
                                <td>{client.name}</td>
                                <td>{client.email}</td>
                                <td>{client.phone}</td>
                                <td>{client.state}</td>
                                <td>{client.province}</td>
                                <td>{client.city}</td>
                                <td>{client.address}</td>
                                <td>{client.contact_name}</td>
                                <td>{client.website}</td>
                                <td>
                                    <button
                                        className="btn btn-success btn-sm me-2"
                                        onClick={() => handleNavigateToUsers(client.id)}>
                                        Usuarios
                                    </button>
                                    <button
                                        className="btn btn-warning btn-sm me-2"
                                        onClick={() => handleEditClient(client)}>
                                        Editar
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDeleteClient(client.id)}>
                                        Borrar
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {isModalOpen && (
                        <ModalClient
                            loadContent={loadContent}
                            isOpen={isModalOpen}
                            onClose={() => {
                                setIsModalOpen(false);
                            }}
                            editingModal={editingClient}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Clients;
