import axios from 'axios';

const apiPublic = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    return axios.create({
        baseURL: baseUrl,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

const apiPrivate = ()=>{
    const baseUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('access_token');
    return axios.create({
        baseURL: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
};

export default {
    apiPublic,
    apiPrivate,
};
