import { format } from "date-fns";

const formatDate = (dateString, includeTime = false) => {
    if(dateString == null){
        return "Indefinido";
    }
    const date = new Date(dateString);
    if (includeTime) {
        return format(date, "dd/MM/yyyy HH:mm");
    }
    return format(date, "dd/MM/yyyy");
};

export default {
    formatDate,
}
