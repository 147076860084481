import React from "react";
import SidebarNav from "../Sidebar/SidebarNav.js";

const Activity = () => {

    return (
        <div className="container-fluid">
            <div className="row vh-100">

                <SidebarNav/>

                <div className="col-md-9 col-lg-10 p-4 content-container">
                    <h2 className="mb-4">Limpieza y Mantenimiento del Parque Central</h2>

                    <div className="row">
                        <div className="col-md-9 row height-min-content">

                            <div className="card-box">

                                <div className="task-status-container">
                                    <div className="task-status-row">
                                        <div className="task-status">
                                            <label htmlFor="current-status">Estado:</label>
                                            <span id="current-status"
                                                  className="status-value in-progress">En progreso</span>
                                        </div>

                                        <div className="task-select">
                                            <label htmlFor="status-selector">Cambiar estado:</label>
                                            <select id="status-selector">
                                                <option value="pendiente">Pendiente</option>
                                                <option value="en_progreso" selected>En progreso</option>
                                                <option value="completado">Completado</option>
                                                <option value="cancelado">Cancelado</option>
                                            </select>
                                        </div>

                                        <div className="task-hours">
                                            <label htmlFor="work-hours">Horas:</label>
                                            <input type="number" id="work-hours" min="0" step="0.1"
                                                   placeholder="Horas"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="form-label">Descripción:</label>
                                    <textarea readOnly className="form-control form-control-lg" rows="5">
                                    Realizar la limpieza general del Parque Central, incluyendo
                                    recolección de residuos, poda de árboles, pintura de bancas
                                    y restauración de áreas verdes. Esta tarea busca promover un
                                    entorno limpio y seguro para los vecinos, fomentando el uso
                                    responsable de los espacios públicos.
                                </textarea>
                                </div>

                                <div className="comment-list-container">
                                    <div className="comment">
                                        <div className="user-info">
                                            <div className="user-avatar">
                                                <span className="initials">AB</span>
                                            </div>
                                            <div className="user-details">
                                                <h5 className="user-name">Ana Beltrán</h5>
                                                <p className="comment-date">Hace 2 horas</p>
                                            </div>
                                        </div>
                                        <p className="user-comment">Este es un comentario de prueba. ¡Adjunto una
                                            imagen!</p>
                                        <div className="attachments">
                                            <div className="image-attachment">
                                                <img src="https://portal.andina.pe/EDPfotografia3/Thumbnail/2019/04/10/000577436W.jpg" alt="Imagen adjunta"/>
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcnDhsiB7Aqg3MEBQf947-Upuwtm2RociIgg&s" alt="Imagen adjunta"/>
                                            </div>
                                            <div className="link-attachment">
                                                <a href="https://example.com" target="_blank">Visitar página web</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="comment">
                                        <div className="user-info">
                                            <div className="user-avatar">
                                                <span className="initials">CF</span>
                                            </div>
                                            <div className="user-details">
                                                <h5 className="user-name">Carlos Fernández</h5>
                                                <p className="comment-date">Hace 1 día</p>
                                            </div>
                                        </div>
                                        <p className="user-comment">Aquí dejo un comentario con un archivo PDF
                                            adjunto.</p>
                                        <div className="attachments">
                                            <div className="pdf-attachment">
                                                <i className="bi bi-file-earmark-pdf"></i>
                                                <a href="#">Archivo PDF adjunto</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="comment">
                                        <div className="user-info">
                                            <div className="user-avatar">
                                                <span className="initials">ML</span>
                                            </div>
                                            <div className="user-details">
                                                <h5 className="user-name">Martín López</h5>
                                                <p className="comment-date">Hace 3 días</p>
                                            </div>
                                        </div>
                                        <p className="user-comment">Aquí hay otro comentario sin adjuntos.</p>
                                    </div>
                                </div>


                                <div className="comment-box-container">
                                    <textarea id="comment-box" placeholder="Escribe un comentario..."></textarea>
                                    <div className="comment-actions">
                                        <button className="action-btn" title="Adjuntar PDF">
                                            <i className="bi bi-file-earmark-pdf"></i>
                                        </button>
                                        <button className="action-btn" title="Adjuntar Imagen">
                                            <i className="bi bi-image"></i>
                                        </button>
                                        <button className="action-btn" title="Adjuntar URL">
                                            <i className="bi bi-link"></i>
                                        </button>
                                        <button className="action-btn" title="Adjuntar Video">
                                            <i className="bi bi-camera-video"></i>
                                        </button>
                                        <button className="send-btn" title="Enviar">
                                            <i className="bi bi-send"></i> Enviar
                                        </button>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className="col-md-3">
                            <div className="card-box">
                                <small>Creado por:</small>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-reportado">
                                        AB
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>Anastasia Barria</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-box">
                                <small>Responsables:</small>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-responsable">
                                        AB
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>Anastasia Barria</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-box">
                                <small>Asignado a:</small>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-asignado">
                                        RA
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>Roberto Andrade</strong></p>
                                    </div>
                                </div>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-asignado">
                                        RO
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>Roxana Oliveira</strong></p>
                                    </div>
                                </div>
                                <div className="profile">
                                    <div className="profile-avatar profile-avatar-asignado">
                                        CJ
                                    </div>
                                    <div className="profile-info">
                                        <p><strong>Cristian Jorquera</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="dates-container card-box">
                                <div className="date-item">
                                    <span className="date-label">Inicio Estimado:</span>
                                    <span className="date-value">2024-12-01</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Fin Estimado:</span>
                                    <span className="date-value">2024-12-10</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Inicio Real:</span>
                                    <span className="date-value">2024-12-02</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Fin Real:</span>
                                    <span className="date-value">2024-12-09</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Creado el:</span>
                                    <span className="date-value">2024-11-29</span>
                                </div>
                                <div className="date-item">
                                    <span className="date-label">Actualizado el:</span>
                                    <span className="date-value">2024-12-05</span>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
}

export default Activity